<template>
  <el-dialog
    v-model="showDeleteBusinessDataSourceDialog"
    width="30%"
    align-center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-businessData-source-delete-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-businessData-source-delete-dialog-header__title">
        {{ t('title.deleteCustomTitle', { name: props.currentData?.name }) }}
      </h4>
    </template>
    <p class="elv-businessData-source-delete-dialog-content__title">
      {{
        props.type === 'SOURCE'
          ? t('message.deletedBusinessDataSourceInfo')
          : t('message.deletedIntegrationBusinessDataInfo')
      }}
    </p>
    <p class="elv-businessData-source-delete-dialog-content__placeholder">
      {{ props.type === 'SOURCE' ? t('message.pleaseEnterSourceName') : t('message.pleaseEnterIntegrationName') }}
    </p>
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" @submit.prevent>
      <el-form-item prop="sourceName">
        <el-input ref="deleteInputRef" v-model="ruleForm.sourceName" />
      </el-form-item>
      <el-checkbox v-model="ruleForm.deleteJournal" :label="t('message.deletedBusinessDataSourceWithJournalInfo')" />
    </el-form>

    <template #footer>
      <el-button
        type="info"
        round
        class="elv-businessData-source-delete-dialog-footer__cancel"
        @click="onCancelDelete"
        >{{ t('button.cancel') }}</el-button
      >
      <el-button
        type="primary"
        round
        class="elv-businessData-source-delete-dialog-footer__delete"
        :loading="deleteLoading"
        @click="onDeleteBusinessDataSource"
        >{{ t('button.delete') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { trim } from 'lodash-es'
import { ElMessage } from 'element-plus'
import BusinessDataApi from '@/api/BusinessDataApi'
import type { FormInstance, FormRules } from 'element-plus'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  type: {
    type: String,
    default: 'SOURCE' // SOURCE, TYPE
  }
})

const { t } = useI18n()
const route = useRoute()

const ruleForm = reactive({
  sourceName: '',
  deleteJournal: true
})
const deleteLoading = ref(false)
const showDeleteBusinessDataSourceDialog = ref(false)
const deleteInputRef = ref()
const ruleFormRef = ref<FormInstance>()
const emit = defineEmits(['onResetData', 'onCheckDialog'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const rules = reactive<FormRules>({
  sourceName: [
    {
      required: true,
      trigger: 'blur',
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(
            props.type === 'SOURCE'
              ? `${t('message.pleaseEnterSourceName')}.`
              : `${t('message.pleaseEnterIntegrationName')}.`
          )
        }
        if (trim(value) !== trim(props.currentData?.name)) {
          return new Error(`${t('message.enterSourceName')}.`)
        }
        return true
      }
    }
  ]
})
// 切换删除弹窗展示
const onCheckDeleteDialog = () => {
  showDeleteBusinessDataSourceDialog.value = !showDeleteBusinessDataSourceDialog.value
}

const onCancelDelete = () => {
  ruleFormRef.value?.resetFields()
  ruleForm.sourceName = ''
  ruleForm.deleteJournal = true
  onCheckDeleteDialog()
  emit('onCheckDialog')
}

/**
 * @description: 删除业务数据源
 */
const onDeleteBusinessDataSource = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        deleteLoading.value = true
        if (props.type === 'SOURCE') {
          await BusinessDataApi.deleteBusinessDataSource(entityId.value, props.currentData?.businessDataSourceId, {
            deleteJournal: ruleForm.deleteJournal
          })
        } else {
          await BusinessDataApi.deleteBusinessDataType(entityId.value, props.currentData?.businessDataTypeId, {
            deleteJournal: ruleForm.deleteJournal
          })
        }
        emit('onResetData')
        ElMessage.success(t('message.success'))
        onCancelDelete()
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        deleteLoading.value = false
      }
    }
  })
}

defineExpose({ onCheckDeleteDialog })
</script>

<style lang="scss">
.elv-businessData-source-delete-dialog {
  width: 480px;
  min-height: 310px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;

    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;

    .elv-businessData-source-delete-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 23px 24px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-businessData-source-delete-dialog-content__title {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      word-break: break-word;
    }

    .elv-businessData-source-delete-dialog-content__placeholder {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .el-input {
      width: 432px;
      height: 44px;
      border-radius: 4px;
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        border: 1px solid #4c7ad2;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
      }

      &.is_focus {
        border: 1px solid #4c7ad2;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
      }
    }

    .el-form-item {
      margin-bottom: 0;

      &.is-error {
        margin-bottom: 16px;

        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }

    .el-checkbox {
      height: 16px;
      margin-top: 8px;

      .el-checkbox__input {
        width: 16px;
        height: 16px;
      }

      .el-checkbox__label {
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    margin-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-businessData-source-delete-dialog-footer__cancel {
      background: #edf0f3;
      color: #636b75;
    }

    .elv-businessData-source-delete-dialog-footer__delete {
      margin-left: 20px;
    }
  }
}
</style>
