<template>
  <el-dialog
    v-model="model"
    width="30%"
    align-center
    destroy-on-close
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-origin-businessData-source-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-origin-businessData-source-dialog-header__title">
        {{ props.currentData?.name }}
      </h4>
    </template>
    <div v-loading="dialogLoading" class="elv-origin-businessData-source-dialog-content">
      <div class="elv-origin-businessData-source-dialog-content-header">
        <div class="elv-origin-businessData-source-dialog-content-header__info">
          <p>
            {{ t('common.originalDataLines') }}:
            <span>{{ originBusinessData?.businessDataSource?.count }}</span>
          </p>
          <p>
            {{ t('common.identifiedJournals') }}:
            <span>{{ originBusinessData?.identifiedJournalCount }}</span>
          </p>
        </div>
        <div class="elv-origin-businessData-source-dialog-content-header__operating">
          <span class="elv-origin-businessData-source-dialog-content-header__operating-label"
            >{{ t('common.identificationStatus') }}:</span
          >
          <el-select
            v-model="originBusinessDataParams.status"
            placeholder=" "
            popper-class="elv-businessData-source-screening-popper"
            @change="onChangePage(1)"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <el-table
        ref="tableListRef"
        :data="tableDataList"
        border
        :height="510"
        class="elv-origin-businessData-source-table"
        header-cell-class-name="elv-origin-businessData-source-table-header__cell"
        header-row-class-name="elv-origin-businessData-source-table-header"
        row-class-name="elv-origin-businessData-source-table-row"
        cell-class-name="elv-origin-businessData-source-table-row__cell"
      >
        <el-table-column type="index" width="40" align="center">
          <template #default="{ row }">
            <span>{{ row.index }}</span>
          </template>
        </el-table-column>

        <el-table-column
          v-for="(item, i) in props.currentData.columns"
          :key="i"
          :label="item"
          width="142"
          :show-overflow-tooltip="true"
        >
          <template #default="{ row, column }">
            {{ row.metadata?.[column.rawColumnKey] }}
          </template>
        </el-table-column>
        <el-table-column
          :label="t('common.identificationStatus')"
          width="140"
          fixed="right"
          class-name="elv-origin-businessData-source-table-row__cell-status"
        >
          <template #default="{ row }">
            <template v-if="row.journalCount">
              <SvgIcon name="sources-sync-done" width="14" height="14" />
              <span
                >{{ row.journalCount }} {{ row.journalCount > 1 ? t('report.journals') : t('report.Journal') }}</span
              >
            </template>
            <template v-else>
              <SvgIcon name="sources-sync-error" width="14" height="14" />
              <span>{{ t('report.unidentified') }}</span>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <elv-pagination
        class="elv-transactions-list-pagination"
        size-popper-class="elv-transactions-pagination__sizes-popper"
        :limit="originBusinessDataParams.limit"
        :current-page="originBusinessDataParams.page"
        :total="originBusinessData.totalCount"
        @current-change="onChangePage"
        @size-change="onChangePageSize"
      />
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import { $t, transformI18n } from '@/i18n/index'
import BusinessDataApi from '@/api/BusinessDataApi'
import { BusinessDataType } from '#/BusinessDataTypes'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const model = defineModel<boolean>({ required: true })

const statusOptions = shallowReactive([
  {
    label: $t('common.all'),
    value: 'ALL'
  },
  {
    label: $t('report.identified'),
    value: 'IDENTIFIED'
  },
  {
    label: $t('report.unidentified'),
    value: 'UNIDENTIFIED'
  }
])

const dialogLoading = ref(false)
const originBusinessData = ref<BusinessDataType>({
  totalCount: 0,
  identifiedJournalCount: 0,
  businessDataType: {
    columns: []
  },
  businessDataSource: null,
  businessDataList: []
})
const originBusinessDataParams = ref({
  page: 1,
  limit: 20,
  status: 'ALL',
  businessDataSourceId: ''
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const tableDataList = computed(() => {
  return originBusinessData.value.businessDataList.map((item: any, i: number) => {
    return {
      ...item,
      // 根据originBusinessDataParams.value.page和originBusinessDataParams.value.limit
      index: i + 1 + (originBusinessDataParams.value.page - 1) * originBusinessDataParams.value.limit
    }
  })
})

/**
 * @description: 获取原始业务数据列表
 */
const fetchOriginBusinessDataList = async () => {
  try {
    dialogLoading.value = true
    const { data } = await BusinessDataApi.getOriginBusinessDataList(entityId.value, originBusinessDataParams.value)
    originBusinessData.value = data
  } catch (error: any) {
    console.error(error)
  } finally {
    dialogLoading.value = false
  }
}

/**
 * @description: 切换每页条数
 * @param {number} limit 每页条数
 */
const onChangePageSize = (limit: number) => {
  originBusinessDataParams.value.limit = limit
  originBusinessDataParams.value.page = 1
  fetchOriginBusinessDataList()
}

/**
 * @description: 切换页码
 * @param {number} page 页码
 */
const onChangePage = (page: number) => {
  originBusinessDataParams.value.page = page
  fetchOriginBusinessDataList()
}

watch(
  [() => model.value, () => props.currentData],
  async () => {
    if (model.value && !isEmpty(props.currentData)) {
      originBusinessDataParams.value.businessDataSourceId = props.currentData.businessDataSourceId
      await fetchOriginBusinessDataList()
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-origin-businessData-source-dialog {
  width: 1000px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-source-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-origin-businessData-source-dialog-content {
      width: 100%;
    }

    .elv-origin-businessData-source-dialog-content-header {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      .elv-origin-businessData-source-dialog-content-header__info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;

        p {
          color: #838d95;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: #1e2024;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 8px;
          }
        }
      }

      .elv-origin-businessData-source-dialog-content-header__operating {
        display: flex;
        align-items: center;
        justify-content: center;

        .elv-origin-businessData-source-dialog-content-header__operating-label {
          color: #838d95;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          white-space: nowrap;
        }

        .el-select {
          height: 15px;
          width: fit-content;

          .el-select__wrapper {
            height: 15px;
            min-height: 15px;
            border: none !important;
            box-shadow: none !important;
            padding: 0;
            padding-left: 8px;

            .el-select__selection {
              width: fit-content;

              .el-select__placeholder {
                position: relative;
                top: 0;
                transform: none;
                color: #1e2024;
                font-family: 'Plus Jakarta Sans';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: fit-content;
                overflow: visible;
              }
            }

            .el-select__suffix {
              .el-icon {
                margin-left: 4px;
                color: #0e0f11;

                svg {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }
      }
    }

    .elv-origin-businessData-source-table {
      --el-table-border-color: #d4dce5;
      --el-table-border: 1px solid #edf0f3;
      --el-fill-color-lighter: #f9fafb;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #ced7e0;
      border-radius: 4px;
      border-bottom-width: 1px;

      .el-table__inner-wrapper {
        width: 100%;

        &::after,
        &::before {
          width: 0;
          height: 0;
        }
      }

      &.el-table--border {
        &::after,
        &::before {
          width: 0;
          height: 0;
        }
      }

      .el-table__body-wrapper {
        height: 616px;

        .el-scrollbar__view {
          width: 100%;
          margin-bottom: 0px !important;
        }
      }

      .el-table__border-left-patch {
        width: 0;
      }

      .elv-origin-businessData-source-table-header {
        background: #eef4fb;

        .elv-origin-businessData-source-table-header__cell {
          background: #eef4fb;
          height: 36px;
          box-sizing: border-box;
          border-right-color: #e4e7eb;
          border-bottom-color: #ced7e0;
          padding: 0px;

          &:last-child {
            border-right: 0px;
          }

          &.is-right .cell {
            justify-content: flex-end;
          }

          .cell {
            font-family: 'Plus Jakarta Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #666d74;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-items: center;
            white-space: nowrap;
          }
        }
      }

      .elv-origin-businessData-source-table-row__cell {
        padding: 0;
        height: 42px;

        &:last-child {
          border-right: 0px;
        }

        &.elv-origin-businessData-source-table-row__cell-status {
          .cell {
            display: flex;
            align-items: center;

            svg {
              margin-right: 8px;
            }
          }
        }

        .cell {
          padding: 0 10px;
          color: #0e0f11;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.is-right .cell {
          text-align: right;
        }

        &.is-center .cell {
          text-align: center;
        }

        &.el-table-column--selection {
          .cell {
            width: 100%;
            padding: 0px;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.elv-businessData-source-screening-popper.el-popper.el-select__popper {
  transform: translateY(-6.5px);

  .el-select-dropdown__item {
    height: 32px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.hover,
    &.is-hovering {
      background: #f9fafb;
    }

    &.selected,
    &.is-selected {
      color: #1753eb;
      font-weight: 500;
    }

    &.is-disabled {
      background-color: #f5f7fa !important;
    }
  }
}
</style>
